<template>
  <div
    v-if="step == 1"
    class="term">
    <div class="icon-print">
      <img
        @click="print"
        src="@/assets/icon-print.png"/>
    </div>
    <div class="page-title">利用規約</div>
    <terms-detail/>
    <div class="agreement-lbl mb-1">
      <label>
        <input v-model="parentAgree" type="checkbox"/>
        親権者の同意を得ています
      </label>
    </div>
    <div>
      <button
        :disabled="!parentAgree"
        @click="nextStep(2)"
        class="btn-bg mt-1">I agree</button>
    </div>
  </div>
  <div v-else-if="step == 2" class="single-scene">
    <select-school
      @submit="selectSchool"
      @back="backOneStep"
      :inProgress="true" />
  </div>
  <div v-else-if="step == 3" class="single-scene">
    <select-grade
      @submit="selectGrade"
      :inProgress="true"
      @back="backOneStep"
      submitMessage="Next" />
  </div>
  <div v-else-if="step == 4" class="single-scene">
    <select-textbook
      @submit="selectTextbook"
      @back="backOneStep"
      :inProgress="true"
      submitMessage="Complete" />
  </div>
</template>

<script>
import SelectSchool from '../../components/molecule/SelectSchool.vue'
import SelectGrade from '../../components/molecule/SelectGrade.vue'
import SelectTextbook from '../../components/molecule/SelectTextbook.vue'
import TermsDetail from '../../components/molecule/TermsDetail.vue'

export default {
  name: 'FirstLogin',
  components: { SelectSchool, SelectGrade, SelectTextbook, TermsDetail, },
  data() {
    return {
      step: 1,
      parentAgree: false,
    }
  },
  methods: {
    print() {
      // https://jsfiddle.net/qeq0sy13/
      const divContents = document.getElementById("term-detail").innerHTML
      const content = window.open('', '', 'width=800')
      content.document.write('<html>')
      content.document.write('<body>')
      content.document.write(divContents)
      content.document.write('</body></html>')
      content.document.close()
      content.print()
      content.close()
    },
    nextStep(step) {
      this.step = step
    },
    selectSchool(school) {
      this.$store.commit('setConfigSchool', school)
      this.step++
    },
    selectGrade(grade) {
      this.$store.commit('setConfigGrade', grade)
      this.step++
    },
    async selectTextbook(id) {
      this.$store.commit('setConfigTextbook', id)
      await this.$store.commit('saveConfig')
      this.$router.push('/menu')
    },
    backOneStep() {
      this.step = this.step - 1
    },
  }
}
</script>

<style lang="scss" scoped>
.agreement-lbl {
  margin-top: 20px;
  font-size: 25px;
  color: #0E1D3A;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    width: 24px;
    height: 24px;
    -moz-transform:	scale(1.4);
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    margin-right: 10px;
    border: 1px solid #0E1D3A;
  }
}
.agree-btn {
  margin-top: 20px;
  background: white;
  border: 1px solid;
  border-radius: 15px;
  padding: 5px 10px;
  width: 120px;
  cursor: pointer;
}
.icon-print {
  display: flex;
  justify-content: flex-end;
  img {
    float: right;
    cursor: pointer;
    margin-right: 20px;
    width: 142px;
    margin: 10px 30px 10px 0;
  }
}

.single-scene {
  padding-top: 84px;
}
</style>